.navigation-container{
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    .navigation-header{
        font-family: 'Libre Bodoni', serif;
        font-size: 22px;
        padding-left: 40px;
    }   
}

.nav-links-container{
display: flex;
align-items: center;
justify-content: flex-end;

.nav-link{
    cursor: pointer;
    font-family: 'Libre Bodoni', serif;
    font-weight: 800;
    font-size: 15px;
    margin-right: 40px;
    text-decoration: none;
    color: #2d2d2d;
    &:hover{
   color: #746c61;
    }
   }
}

/* Example CSS styles */
.navbar {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    color: black;
    width: 100%;
  }
  
  .navbar__toggle {
    font-size: 1.5rem;
    cursor: pointer;
    padding-top: 2px;
  }
  
  .navbar__menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    align-items: flex-start;
    position: absolute;
    top: 8%;
    left: 0;
    width: 200px;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

  .navbar_item{
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Libre Bodoni', serif;
    font-weight: 800;
    padding: 10px;
    text-decoration: none;
    color: #2d2d2d;
    &:hover{
    color: #746c61;
      }
    }
  }

  
  .navbar__menu.active {
    opacity: 1;
    visibility: visible;
  }


@media screen and (max-width: 768px){
}



   
//  @media (max-width: 696px){

//     .logo-container{
//         height: 100%;
//          width: 120px;
//          margin-left: 5px;
      
//      }
//     .nav-links-container{

// margin-left: 5px;

//         .nav-link{

//             font-size: 10px;
//         }

//     }
     

// }


// @media screen and (max-width: 767px){

//     .navigation-container{

//         display: flex;
//         justify-content: space-between;
//         background-color: white;
//         .nav-link{
//             padding: 1px 13px;
//             cursor: pointer;
//             font-family: 'Libre Bodoni', serif;
//             font-weight: 800;
//             font-size: 10px;
//             text-decoration: none;
//             color: #2d2d2d;
//             &:hover{
//            color: #746c61;
//             }
//            }
    
//         h3{
//             font-family: 'Libre Bodoni', serif;
//             font-size: 10px;
//             visibility: hidden;
//         }
//     }

// }