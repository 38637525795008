.photos-container{
width: 70%;
margin: auto;
margin-top: 50px;
margin-bottom: 40px;
}

.text-container-about-us-second{
flex-direction: row;
}

.text-container{

}

@media screen and (max-width: 767px){

    .photos-container{
        width: 90%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
        }

        .text-container{
width: 100%;
padding: 0px;
padding-top: 20px;
padding-bottom: 20px;
margin-top: 100px;
        }
}