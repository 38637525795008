



.reviews-container-main{
    margin-top: 100px;
    font-family: 'Libre Bodoni', serif;
    h2{
        font-size: 50px;
        text-align: center;
        opacity: 0.8;
     }
     h5{
        width: 70%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        opacity: 0.8;
     }


}


.reviews-container{


    display: grid;
grid-template-columns: 400px 400px 400px;
justify-content: center;
margin-bottom: 20px;

        max-width: 90%;
        overflow-y:scroll;
        padding:0%;
        margin: 5%;
 
  
  .review-box{
    background-color: rgba(#fffbf0, 0.4);
    margin-bottom: 40px;
    margin-right: 40px;
    padding: 20px;
        color: black;
  }
  .review-box-header{
    display: flex; 
    h4{
       margin-left: 10px;
    }
   }

};

   
@media screen and (max-width: 767px){

    .reviews-container-main{
        margin-top: 100px;
        font-family: 'Libre Bodoni', serif;
        h2{
            font-size: 30px;
            text-align: center;
            opacity: 0.8;
         }
         h5{
            width: 70%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            font-size: 18px;
            opacity: 0.8;
         }
    
    
    }
    
    
    .reviews-container{
    
        display: grid;
    grid-template-columns: 300px ;
    
    justify-content: center;
    margin-bottom: 10px;
            max-width: 90%;
            overflow-y:scroll;
            padding:0%;
            margin: 0;
     
      
      .review-box{
        background-color: rgba(#fffbf0, 0.4);
        margin-bottom: 20px;
        margin-right: 0px;
      
        width: 300px;
        padding: 10px;
            color: black;
            font-size: 12px;
      }
      .review-box-header{
        display: flex; 
  
        h4{
           margin-left: 10px;
     
        }
       }
    
    };
    
    
       
    
    
           
         
          
    
          
    
    
    
   
      
        
}

