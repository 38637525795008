.footer-container{
    font-family: 'Libre Bodoni', serif;
    img{
        margin-top: 20px;
        margin-left: 20px;
        height: 100px;
    }

    h4{
        opacity: 0.7;
        font-family: 'Playfair Display', serif;
    }

display: grid;
grid-template-columns: 30% 40% 30% ;
grid-template-rows: 100px 100px;
background-color: rgba(#fffbf0, 0.7);
margin-top: 200px;
padding-bottom: 10px;


.button{
    background-color:  #0464a4;
    width: 200px;
    height: 50px;
    font-family: 'Libre Bodoni', serif;
    border: none;
    color: white;
    font-size: 16px;
grid-row-start: 2;
grid-column-start: 1;
margin-top: 50px;
margin-left: 10px;
color: #e4ddf4;

.link{
    text-decoration: none;
    color: #e4ddf4;
    font-family: 'Libre Bodoni', serif;
    font-weight: 800;
    }
}

.phone-container{
grid-column-start: 2;
grid-row-start: 1;
grid-row-end: 3;
border-left: solid 2px ;
padding-left: 20px;
margin: 30px;
    }

    .social-media-container{
        grid-column-start: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        border-left: solid 2px;
        margin: 30px;
        padding-left: 20px;
        display: grid;
        padding-top:25px ;
        opacity: 0.8;
    }

}

@media screen and (max-width: 767px){

    .footer-container{
        font-family: 'Libre Bodoni', serif;
        img{
            margin-top: 30px;
            margin-left: 0px;
            height: 80px;
            width: 160px;
        }
    
        h4{
            opacity: 0.7;
            font-family: 'Playfair Display', serif;
        }
    
    display: grid;
    grid-template-columns: 30% 40% 30% ;
    grid-template-rows: 100px 100px;
    background-color: rgba(#fffbf0, 0.7);
    margin-top: 200px;
    padding-bottom: 10px;
    
    
    .button{
        background-color:  #0464a4;
        width: 150px;
        height: 50px;
        font-family: 'Libre Bodoni', serif;
        border: none;
        color: white;
        font-size: 13px;
    grid-row-start: 2;
    grid-column-start: 1;
    margin-top: 30px;
    margin-left: 10px;
    color: #e4ddf4;
    
    .link{
        text-decoration: none;
        color: white;
        font-family: 'Libre Bodoni', serif;
        font-weight: 100;
      
        }
    }
    
    .phone-container{
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    border-left: solid 2px ;
    padding-left: 20px;
    margin: 30px;
    overflow: hidden;
    visibility: hidden;
        }
    
        .social-media-container{
            grid-column-start: 3;
            grid-row-start: 1;
            grid-row-end: 3;
            border-left: solid 2px;
            margin: 30px;
            padding-left: 10px;
            display: grid;
            padding-top:20px ;
            opacity: 0.8;
    
         
        }
    
    }
    
   
    
}
