.submit-button{
background-color: #943d24;
border: none;
color: white;
cursor: pointer;
}

.contact-us-container{
background-color: #0464a4;
opacity: 0.8;
z-index: 3;
display: flex;
flex-direction: column;
gap: 10;
margin-top: 50px;
padding: 20px;

h2{
    text-align: start;
    color: white;
    font-size: 40px;
    opacity: 0.8;

}

}

input:focus{
    background-color: #e4ddf4;
}


.contact-container{
display: grid;
grid-template-columns: 280px 280px ;
grid-template-rows: 80px 80px 80px 80px 150px 80px;

.contact-box-first-name{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1; 
    margin: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: #e4ddf4;
    border:    #e4ddf4;
    padding: 10px;
}


.contact-box-field-email{
    grid-column-start: 1;
    grid-row-start: 2;
    margin: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color:  #d6cfe6;
    border:    #e4ddf4;
    padding: 10px;
}
.contact-box-field-phone{
    grid-column-start: 2;
    grid-row-start: 2;
    margin: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: #d6cfe6;
    border:    #e4ddf4;
    padding: 10px;
}
.contact-box-field-moveFrom{
   
    margin: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: #d2cce2;
    border:    #e4ddf4;
    padding: 10px;
}

.contact-box-field-moveto{
  
    margin: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: #d2cce2;
    border:    #e4ddf4;
    padding: 10px;
}

.contact-box-field-movedate{
    margin: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: #d2cce2;
    border:    #e4ddf4;
    padding: 10px;


}

.contact-box-field-aptSize{
    margin: 10px;
    border-radius: 5px 5px 5px 5px;
    background-color: #d2cce2;
    border:    #e4ddf4;
    padding: 10px;
}


.details-box{

margin: 10px;
border-radius: 5px 5px 5px 5px;
background-color: #bfb9ce;
border:    #e4ddf4;
padding: 10px;
grid-column-start: 1;
grid-column-end: 3;
}

}

.submit-button{
    background-color:  #7699c3;
    width: 150px;
    height: 50px;
    grid-column-start: 1;
    grid-row-start: 6; 
    margin: 10px; 
    font-family: 'Libre Bodoni', serif;
    font-size: 16px;

    }


    @media screen and (max-width: 767px){

        .contact-us-container{
          padding: 0;
          width: 95%;
        }

        .contact-container{
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 80px 80px 80px 80px 150px 80px;
            
            .contact-box-first-name{
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1; 
                margin: 10px;
                border-radius: 5px 5px 5px 5px;
                background-color: #e4ddf4;
                border:    #e4ddf4;
                padding: 10px;
            }
            
            
            .contact-box-field-email{
                grid-column-start: 1;
                grid-row-start: 2;
                margin: 10px;
                border-radius: 5px 5px 5px 5px;
                background-color:  #d6cfe6;
                border:    #e4ddf4;
                padding: 10px;
            }
            .contact-box-field-phone{
                grid-column-start: 2;
                grid-row-start: 2;
                margin: 10px;
                border-radius: 5px 5px 5px 5px;
                background-color: #d6cfe6;
                border:    #e4ddf4;
                padding: 10px;
            }
            .contact-box-field-moveFrom{
               
                margin: 10px;
                border-radius: 5px 5px 5px 5px;
                background-color: #d2cce2;
                border:    #e4ddf4;
                padding: 10px;
            }
            
            .contact-box-field-moveto{
              
                margin: 10px;
                border-radius: 5px 5px 5px 5px;
                background-color: #d2cce2;
                border:    #e4ddf4;
                padding: 10px;
            }
            
            .contact-box-field-movedate{
                margin: 10px;
                border-radius: 5px 5px 5px 5px;
                background-color: #d2cce2;
                border:    #e4ddf4;
                padding: 10px;
            
            
            }
            
            .contact-box-field-aptSize{
                margin: 10px;
                border-radius: 5px 5px 5px 5px;
                background-color: #d2cce2;
                border:    #e4ddf4;
                padding: 10px;
            }
            
            
            .details-box{
            
            margin: 10px;
            border-radius: 5px 5px 5px 5px;
            background-color: #bfb9ce;
            border:    #e4ddf4;
            padding: 10px;
            grid-column-start: 1;
            grid-column-end: 3;
            }
            
            }
    }