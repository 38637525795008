.text-container{
    width: 70%;
    margin: auto;
    padding: 40px;
    text-align: center;

    font-family: 'Libre Bodoni', serif;
    background-color: #fffbf0;
    opacity: 0.9;
    color: #282612;
    margin-top: 200px;
    
}
.text-container-about-us-second{
flex-direction: row;
display: flex;
}
@media screen and (max-width: 767px) {
    .text-container-about-us-second{
        flex-direction: column;
        display: flex;
        }
}

//     .text-container{

//         margin-top: 500px;
//         width: 90%;
//         padding: 10px;

//     }

// }