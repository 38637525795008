body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://s3.us-west-2.amazonaws.com/moveviking.com/images/box.jpg");
  background-repeat: no-repeat;
  background-size:cover;
  background-attachment: fixed; 
  display: block;
  margin: 0;
  padding: 0;
  width: 100vw;
  display: flex;
}

#root {
  overflow-x: hidden;
}
/* 
#root {
    width: 100%;
    height: 100vh; 
    display: flex;
    flex-direction: column;
    justify-content: stretch; 
} */

