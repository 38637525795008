.text-container-about-us{

    background-color: rgba(#fffbf0, 0.5);
    font-family: 'Libre Bodoni', serif;
    display: flex;


    .logo-text{
     width: 50%;
     padding: 30px;
     padding-left: 100px;
     padding-top: 80px;
     color: #746c61;
     padding-bottom: 80px;

     img{
width: 200px;
height: 100px;

     }
        h2{
            font-size: 60px;
            margin-bottom: 50px;
        }

        .phone-number{
     
            font-size: 30px;
            
        }

        .email{
            color:  #0464a4;
            font-size: 22px;  
            cursor: pointer;
            font-weight: 500;
        }

        button{
            background-color:  #0464a4;
            opacity: 0.8;
            width: 280px;
            border-radius: 20px;
            height: 50px;
            font-family: 'Libre Bodoni', serif;
            border: none;
            color: white;
            cursor: pointer;
        }

    }

}

.that-we-offer-container{
  
    bottom: 80px;
    left:20px;
    font-family: 'Libre Bodoni', serif;
    margin-top: 100px;
margin-left: 20%;

    h2{
justify-content: center;
text-align: center;
font-size: 50px;
    }
    p{
        text-align: center;
        width: 60%;
      margin: auto;
    }


    .that-offer-single-container{

        margin-top: 30px;
        display: grid;
        grid-template-columns: 40% 40%  ;
        grid-template-rows: 400px 400px 400px;
        justify-content: center;
        font-family: 'Libre Bodoni', serif;
        font-weight: 500;

  .that-offer-single{
    animation-name: fadeOut;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    transition: 1s opacity, 1s visibility;
    opacity: 1;
   
margin-top: 30px;
@keyframes fadeOut
{
    from{
        opacity: 0;
        visibility: hidden;
    }
    
    to{
        opacity: 1;
        visibility: visible;
    }
}



img{

margin-left: auto;
margin-right: auto;
display: block;
}

h3{
    text-align: center;
}
  }
    
    }

}


.main-text{
    justify-self: center;

li{
display: flex;

}

}

.header-container {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 767px){

   .header-container   {
     display: flex;
     flex-direction: column;
    }
    .text-container-about-us{
    .logo-text{
padding-left: 10px;
padding-top: 20px;
padding-bottom: 10px;
width: 100%; 
      h2{
    font-size: 32px;
    width: 70%;
    margin-bottom: 30px;
       }

       .email{
        font-size: 16px;
       }
     }
}

.that-we-offer-container{
  
    bottom: 80px;
    left:20px;
    font-family: 'Libre Bodoni', serif;
    margin-left: 0;
    p{
    width: 90%;
    font-weight: 600;
    }
    .that-offer-single-container{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        font-family: 'Libre Bodoni', serif;
        flex-direction: column;
        font-weight: 600;
     }  
}

.reviews-container-main h5 {
width: 90%;

}

 
}

// @media screen and (max-width: 767px){

// .text-container-about-us{

//     width:100%;
//     .logo-text{
//      margin-left:5px ;
//      width: 90%;
//      padding: 10px;
//      padding-top: 70px;
//      color: #746c61;
//      padding-bottom: 80px;

//      img{
// width: 200px;
// height: 100px;
// margin-left: 0;

//      }
//         h2{
//             font-size: 30px;
//             margin-bottom: 10px;
//             width: 350px;
//         }

//         h3{
//             color:  #0464a4;
//             font-size: 20px;
            
//         }
//         button{
//             background-color:  #0464a4;
//             opacity: 0.8;
//             width: 80px;
//             height: 30px;
//             grid-column-start: 1;
//             grid-row-start: 5; 
//             margin-left: 0px; 
//             font-family: 'Libre Bodoni', serif;
//             border: none;
//             color: white;
//             font-size: 10px;

//         }

//     }

// }

// .that-we-offer-container{
  
//     bottom: 80px;
//     left:20px;
//     font-family: 'Libre Bodoni', serif;
//     margin-top: 500px;
// margin-left: 0;

//     h2{
// justify-content: center;
// text-align: center;
// font-size: 20px;
//     }
//     p{
//         text-align: center;
     
//       margin: auto;
//     }


//     .that-offer-single-container{
// font-size: 12px;
// margin-top: 20px;
//         display: grid;
//         grid-template-columns: 50% 50%  ;
//         grid-template-rows: 500px 500px 500px;
//   justify-content: center;
//   font-family: 'Libre Bodoni', serif;

 

//   .that-offer-single{
//     animation-name: fadeOut;
//     animation-duration: 3s;
//     animation-fill-mode: forwards;
//     transition: 1s opacity, 1s visibility;
//     opacity: 1;
   
// margin-top: 30px;
// @keyframes fadeOut
// {
//     from{
//         opacity: 0;
//         visibility: hidden;
//     }
    
//     to{
//         opacity: 1;
//         visibility: visible;
//     }
// }

// img{

// margin-left: auto;
// margin-right: auto;
// display: block;
// }

// h3{
//     text-align: center;

// }
//   }
    
//     }

// }




// .main-text{
//     justify-self: center;

// li{
// display: flex;

// }
// }

// }