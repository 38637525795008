.contact-route-form{
margin-left: 400px;
margin-top: 200px;
}
.text-container-about-us-second{
    display: flex;
     flex-direction: row;
}
.logo-text{
    padding-left: 100px;
}

@media screen and (max-width: 767px){

    .text-container-about-us-second{
        flex-direction: column;
    }

}